@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,700&subset=latin-ext');

$font-stack:    'Roboto', sans-serif;
$primary-color: #0692d2;
$primary-color:#00aeef;
$primary-color-hover:#00a2de;
$border-color:#e7e7e7;
$font-color:#657184;

$topbar-height:35px;
$navbar-height:75px;

$education-image-height:250px;
$education-box-border-color:#e4e8ee;
$title-color:#263238;

body
{
  font-family: $font-stack;
  color:$font-color;
}
h1, h2, h3, h4, h5
{
  color:$title-color;
}
label
{
  font-weight: normal;
}
.panel
{
    border-radius: 0;
}
.navbar-brand img
{
    height: 100%;
}
.footer
{
  margin-top:100px;
  background-color: #fafafa;
}
.footer h5
{
    margin-bottom: 2em;
}
.footer h5.right
{
    text-align: right;
}
.footer .container
{
    padding: 30px 15px;
}

.form-control {
    background-color: #fafafa;
    border: 0;
    border-radius: 2px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.11);
}


.btn
{
  background-color: $primary-color;
  border-radius: 2px;
  border:0;
  padding: 6px 22px;
}
b
{
  color:$title-color;
}
.animated-object
{
    opacity: 0;
}
.home-info h1
{
  font-weight: 300;
  color:white;
  text-align: center;
}
.home-info-content
{
  padding:11em 0 1.5em;
}
.navigation-arrow
{
    margin-top:13em;
    padding:2em;
    cursor: pointer;
}
.navigation-arrow img
{
    position: relative;
}
.home-info-content .white-logo
{
  width:38%;
}
.home-info-content .btn
{
  margin-top:50px;
  width: 20%;
}
.home-info-content .btn:first-child
{
  margin-right:2%;
}
.home-info-content .btn:last-child
{
  margin-left:2%;
}
.home-info b
{
  font-weight: normal;
  color:white;
}
.home-info
{
  text-align: center;
  color:white;
  background: url('/img/home-main.png')no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  margin-top:-25px;
}
.home-info p
{
  margin-top:3em;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}
.home-laptop
{
    padding: 3em 0;
    text-align: center;
}
.home-laptop img
{
    width: 80%;
}
.home-laptop h3
{
    color:#707070;
    font-size: 16px;
    letter-spacing: .32em;
    margin-top:3.5em;
    margin-bottom: 1em;
}
.home-laptop h4
{
    font-size: 35px;
    margin-bottom: 0.5em;
}
.home-laptop p
{
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}
.home-features
{
    text-align: center;
}
.home-features h3
{
    font-size:.9em;
    font-weight: bold;
}
.home-features p
{
    font-size: .9em;
    height: 100px;
}
.home-features hr
{
    width: 15%;
}
.home-features .responsive-circle
{
    width: 100%;
}
.home-features .responsive-circle::after
{
    border: 1px solid $border-color;
    transition: background-color 0.5s ease;
}
.home-features .bodovanje .responsive-circle::after {

    background: url('/img/bodovanje.png') center no-repeat;
}
.home-features .pristupacno .responsive-circle::after {

    background: url('/img/247.png') center no-repeat;
}
.home-features .inovativnost .responsive-circle::after {

    background: url('/img/inovativnost.png') center no-repeat;
}
.home-features .edukativno .responsive-circle::after {

    background: url('/img/edukativno.png') center no-repeat;
}
.home-features .uspjeh .responsive-circle::after {

    background: url('/img/uspjeh.png') center no-repeat;
}
.home-features .jednostavnost .responsive-circle::after {

    background: url('/img/jednostavnost.png') center no-repeat;
}
.home-features .responsive-circle:hover::after {
    background-color: $primary-color;
}
.home-video
{
    background:#fafafb;
    padding: 4em 0;
    overflow: hidden;
}
.home-video img
{
    width: 100%;
}
.home-video p
{
    width: 90%;
    font-size: .9em;
    margin-top:3em;
}
.home-info-line
{
    background:$primary-color;
    padding: 4em 0;
}
.home-info-line img
{
    width: 100%;
}
.home-info-line .white-border
{
    border-right: 1px solid white;
    padding: 10px;
    padding-left: 0;
}
.home-info-line h3
{
    font-size: 1.3em;
    margin:5px 0;
    color: white;
}
.home-info-line .btn
{
    border:1px solid white;
    float: right;
    margin-right: 1em;
    margin-top:1.5em;
}
.home-info-line p
{
    color: white;
    font-size: .9em;
}
.home-contact
{
    padding: 4em .5em;
}
.home-contact img
{
    width: 90%;
}
.home-contact .btn
{
    width: 100%;
}
.home-contact input, .home-contact textarea
{
    background-color: #fffbfb;
    border-bottom: 3px solid $primary-color;
    width: 100%;
    margin-bottom: 25px;
}
.home-contact textarea
{
    height:190px;
}
.contact-title span
{
    font-size: 1.5em;
}
.contact-title
{
    font-size: 2em;
    font-weight: bold;
    border-left: 4px solid $primary-color;
    padding-left: .2em;
    color:black;
    line-height: 1em;
    margin-bottom: 2em;
}
.page-heading p
{
 font-size:13px;
 margin-bottom:5px;
}
.page-heading h4
{
 font-size:22px;
}
.page-heading hr
{
  background:$primary-color;
  width:3.125em;
  float:left;
  height:2px;
  margin-top:5px;
}
.page-heading
{
  margin-bottom:60px;
}
.question_header
{
  padding:2em 1em;
  background: #f7f8fc;
  border:1px solid $border-color;
}
.question_header b
{
  font-size: 1.3em;
}
.question_container
{
  margin-bottom: 1.5em;
}
.question_container .radio
{
  padding:0 1.5em;
  border:1px solid $border-color;
  border-top:0;
  margin:0;
  transition: background-color 0.5s ease;
}
.question_container .radio label
{
  width:100%;
  padding-top:1em;
  padding-bottom:1em;
}
.question_container .radio:hover
{
  background-color: #f3f4f8;
}
.responsive-circle {
    font-size: 14px;
    margin: 0 auto 0;
    width: 80%;
}
.responsive-circle::after {
    border-radius: 50%;
    content: "";
    display: block;
    height: 0;
    padding-bottom: 100%;
    width: 100%;
    background:$primary-color;
}
.responsive-circle div {
    color: white;
    float: left;
    line-height: 1em;
    margin-top: -1em;
    padding-top: 50%;
    text-align: center;
    width: 100%;
}
.details-column
{
  line-height:1.8em;
  font-size:.9em;
  padding:0 .7em;
}
.details-column .btn
{
    margin-bottom: 1em;
}
.correct-answer
{
  background-color: #a5eaa5;
  padding:10px 5px;
  border-radius:5px;
}
.wrong-answer
{
  background-color: #ffa0a0;
  padding:10px 5px;
  border-radius:5px;
}
#time-hide
{
  font-size:.5em;
  cursor:pointer;
}
.stats-box .btn
{
  height:4.2em;
  background-color: #5cb85c;
}

.stats-box .btn:hover
{
  background-color: #5ab05a;
}
.stats-box .progress
{
  margin-top:1em;
}
.stats-box .progress-bar
{
     background-color: #6b7294 !important;
}
.stats-box
{
  font-size: 1.5em;
  text-align: center;
  background-color: #00aeef;
  width:100%;
  padding:.7em;
  color: white;
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 1;
}
.education-info,.education-image, .education-content
{
   margin-top:15px;
    word-wrap: break-word;
}
.education-info .btn
{
    width: 80%;
    text-align: center;
}
.education-content .tab-pane
{
  margin-top:20px;
}
.education
{
  border:1px solid $education-box-border-color;
  margin-bottom:40px;
}

.education h1 a
{
    color:$title-color;
    font-weight: 600;
}
.education h1
{
   font-size: 2em;
}



.education-description
{
  padding:0 3em;
  padding-top:.875em;
  font-size:0.85em;
  height:250px;
}
.education-description p
{
    padding-right:15px;
}
.education-buttons
{
  text-align: center;
  margin-top:5.3em;
  position:absolute;
  right:25px;
  bottom:25px;
}

.education-img img
{
  width:100%;
  height: $education-image-height;
}
.materials-pane a
{
  padding:15px 22px;
  width:100%;
  background: #f2f2f2;
  float:left;
  font-weight: bold;
  color:$title-color;
  text-decoration: none;
  transition: background-color 0.25s ease;
}
.materials-pane a:hover
{
  background-color: #eaeaea;
}
.education-image img
{
   width:100%;
}
.material-modal .modal-header
{
  padding:25px 20px;
}
.material-modal .modal-footer,.material-modal .modal-body
{
  padding:25px 20px;
}
.results-container
{
    margin-top:3em;
}
.pagination > .active > a, .pagination > .active > a:hover, .pagination > .active > a:focus, .pagination > .active > span, .pagination > .active > span:hover, .pagination > .active > span:focus {
    background-color: #0094cb !important;
    border-color: #0087b9 !important;
}
.pagination > li:first-child > a, .pagination > li:first-child > span {
    border-bottom-left-radius: 2px !important;
    border-top-left-radius: 2px !important;
}
.form-horizontal .form-group {
    margin-right: 0px !important;
    margin-left: 0px !important;
}
.education-navigation .btn
{
    width:100%;
    margin-bottom: 10px;
}
.user-educations-table
{
    margin-top:6em;
}
.user-educations-table .glyphicon-ok-sign
{
    color:$brand-success;
}
.user-educations-table .glyphicon-remove-sign
{
    color:$brand-danger;
}
.user-educations-table .pass-icon
{
    font-size: 1.5em;
    text-align: center;
}
.user-profile-name
{
    font-weight: bold;
    font-size: 1.5em;
    color:black;
    line-height: 1.7em;
}
.user-profile-name .lastname
{
    font-size: 2.5em;
}
.detail-centered
{
    margin-left:auto;
    margin-right:auto;
}
.news-image img
{
    width: 100%;
}
.news-body
{
    margin-top: 3em;
}
.news .education
{
    float:left;
    width: 100%;
}
/* Landscape phones and portrait tablets */
@media (max-width: 767px) {


    .footer
    {
        margin-top:30px;
    }
    .navigation-arrow {
        margin-top: 3em;
    }
    .home-info-content {
        padding: 4em 0 1.5em;
    }
    .home-info-content .btn
    {
        width:50%;
    }
    .home-info-content .btn:last-child
    {
        margin-left: 0;
        width: 50%;
        margin-top: 20px;
    }
    .home-laptop
    {
        padding:0.5em 0;
    }
    .white-logo, .home-contact img
    {
        display:none;
    }
    .home-info-line h3
    {
        font-size: 2em;
    }
    .stats-box .btn
    {
      height:2.5em;
    }
    .education-buttons
    {
      text-align: left;
      margin-top:5.3em;
      position:initial;
    }
    .education-buttons a
    {
        margin-right: 1em;
        margin-bottom:1em;
        float:left;
    }
}
@media (min-width: 768px)
{
  .border-column
  {
    border-left:1px solid $border-color;
  }
  .border-column-both
  {
    border-left:1px solid $border-color;
    border-right:1px solid $border-color;
  }
  .is-flex
   {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .is-flex > [class*='col-']
  {
      display: flex;
      flex-direction: column;
  }
  .profile .is-flex > [class*='col-']
  {
      min-height: 120px;
      justify-content: center;
  }
  .education
  {
    border:1px solid $education-box-border-color;
    margin-bottom:40px;
  }
  .education-description
  {
    padding:0 3em;
    padding-top:.875em;
    font-size:0.85em;
    height:250px;
  }
  .education-description p
  {
      padding-right:15px;
  }


  .education-img img
  {
    width:100%;
    height: $education-image-height;
  }
  .topbar
  {
    height:$topbar-height;
    border-bottom:1px solid $border-color;

  }
  .header-bar.navbar
  {
    background-color: white;
    height:$navbar-height;
  }
  .header-bar.navbar .dropdown-toggle
  {
    background-color: $primary-color;
    color:white !important;
  }
  .navbar-default .navbar-nav>.open>a
  {
    background-color: $primary-color !important;
    color:white !important;
  }
  .drop-nav>li>a:focus, .drop-nav>li>a:hover
  {
    background-color: $primary-color-hover !important;
    color:white !important;
  }
  .header-bar.navbar .dropdown-menu>li>a
  {
     color:white !important;
     border-top: 1px solid rgba(255,255,255,.2);
     padding: 10px 20px;
  }
  .header-bar.navbar .dropdown-menu>li>a:hover
  {
     background-color:$primary-color-hover;
  }
  .header-bar.navbar .dropdown-menu
  {
    width:100%;
    min-width:inherit;
    margin-top:-.95em !important;
    border-radius:0;
    padding-bottom: 0;
    border:1px;
    background-color: $primary-color;
    box-shadow:none;
  }
  .header-bar.navbar .dropdown-menu
  {
    color:white;
  }
  .header-bar.navbar .container, .header-bar .navbar-header,.header-bar .navbar-brand img, .header-bar .navbar-brand
  {
    height:100%;
  }
  .navbar-collapse
  {
    float:right;
  }
  .header-bar .nav li
  {
    height:$navbar-height;
    display: flex;
    align-items: center;
  }
  .header-bar .dropdown li
  {
    height:auto;
    display:block;

  }
}
